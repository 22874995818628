body {
    color: #424445;
}

dl {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

dt {
    margin: 0;
    width: 20%;
    font-weight: bold;
}

dd {
    margin: 0;
    width: 80%;
}


.MuiTableCell-head {
    font-weight: bold !important;
}
